<template>
<div class="mainBox">
	<!-- 账号列表 -->
	<div class="con" v-if="isMobile==0">
		<!-- PC及适配 -->
		<div class="container">
			<div class="header">
				<p v-if="accountList.length">{{$t('account.title')}}</p>
				<span v-else>{{$t('account.create')}}</span>
			</div>
			
			<div class="content">
				<div class="content-container">
					<template v-if="accountList.length">
						<div class="account_box">
							<div class="btnBox">
								<div class="g_btn btn" @click="handleCreate">{{$t('account.bind')}}</div>
							</div>
							<div class="listbox">
								<div class="item" v-for="(item,index) in accountList" :key="index" :class="{active: activeItem == index}">
									<div>
										<div class="caption">
											<div class="left">{{item.name}}</div>
											<div class="right g_btn" @click="jumpSubManage(item)" v-if="item.wallet_type==1||item.wallet_type==2">{{$t('common.subManage')}}</div>
										</div>
										<div class="bd">
											<div class="li">
												<div class="title">{{$t('common.exchange')}}</div>
												<div class="text">{{item.web_name}}</div>
											</div>
											<div class="li">
												<div class="title" v-if="item.wallet_type==1||item.wallet_type==2">{{$t('account.walletAddress')}}</div>
												<div class="title" v-else>API Key</div>
												<div class="text">{{item.key}}</div>
											</div>
											
											<div class="li">
												<div class="title">
													{{
														accountFormat2(item.web,item.wallet_type).isT2==1?
														$t('account.'+accountFormat2(item.web,item.wallet_type).label2):
														accountFormat2(item.web,item.wallet_type).label2
													}}
												</div>
												<div class="text">{{item.secret}}</div>
											</div>

											<div class="li">
												<div class="title" v-if="accountFormat3(item.web,item.wallet_type).showLabel3==1">
													{{
														accountFormat3(item.web,item.wallet_type).isT3==1?
														$t('account.'+accountFormat3(item.web,item.wallet_type).label3):
														accountFormat3(item.web,item.wallet_type).label3
													}}
												</div>
												<div class="title" v-else>Account-ID</div>
												<div class="text">{{item.account_id}}</div>
											</div>
											<div class="li">
												<div class="title">{{$t('common.operation')}}</div>
												<div class="text">
													<span class="btn change" @click="handleChange(index)">{{$t('common.modify')}}</span>
													<span class="btn delete" @click="handleDelete(index)">{{$t('common.del')}}</span>
												</div>
											</div>
										</div>

									</div>
								</div>
							</div>
						</div>
					</template>
					<el-empty v-else :image="emptyImg" :image-size="120" :description="$t('account.toCreate')">
						<div class="g_btn btn" @click="handleCreate">{{$t('account.newAccount')}}</div>
					</el-empty>
				</div>
			</div>
		</div>
			
	</div>
	<div class="con" v-else-if="isMobile==1">
		<!-- 移动端判定 -->
		<div class="phoneContainer">
			<template v-if="accountList.length">
				<div class="account_box">
					<div class="listbox">
						<div class="item" v-for="(item,index) in accountList" :key="index" :class="{active: activeItem == index}">
							<div>
								<div class="caption">
									<div class="left">{{item.name}}</div>
									<div class="right g_btn" @click="jumpSubManage(item)" v-if="item.wallet_type==1||item.wallet_type==2">{{$t('common.subManage')}}</div>
								</div>
								<div class="bd">
									<div class="li">
										<div class="title">{{$t('common.exchange')}}</div>
										<div class="text">{{item.web_name}}</div>
									</div>
									<div class="li">
										<div class="title" v-if="item.wallet_type==1||item.wallet_type==2">{{$t('account.walletAddress')}}</div>
										<div class="title" v-else>API Key</div>
										<div class="text">{{item.key}}</div>
									</div>
									<div class="li">
										<div class="title">
											{{
												accountFormat2(item.web,item.wallet_type).isT2==1?
												$t('account.'+accountFormat2(item.web,item.wallet_type).label2):
												accountFormat2(item.web,item.wallet_type).label2
											}}
										</div>
										<div class="text">{{item.secret}}</div>
									</div>
									<div class="li">
										<div class="title" v-if="accountFormat3(item.web,item.wallet_type).showLabel3==1">
											{{
												accountFormat3(item.web,item.wallet_type).isT3==1?
												$t('account.'+accountFormat3(item.web,item.wallet_type).label3):
												accountFormat3(item.web,item.wallet_type).label3
											}}
										</div>
										<div class="title" v-else>Account-ID</div>
										<div class="text">{{item.account_id}}</div>
									</div>
									<div class="li">
										<div class="operation">
											<span class="g_btn btn change" @click="handleChange(index)">{{$t('common.modify')}}</span>
											<span class="g_btn btn delete" @click="handleDelete(index)">{{$t('common.del')}}</span>
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
			</template>
			<el-empty class="isPhone" v-else :image="emptyImg_phone" :image-size="120" :description="$t('account.toCreate')">
				<div class="g_btn btn" @click="handleCreate">{{$t('account.newAccount')}}</div>
			</el-empty>
		</div>
	</div>

	<!-- 创建账户、修改账户 -->
	<el-dialog :title="isEdit?$t('account.editAcc'):$t('account.create')" :visible.sync="isPop" @close="closePop">
		<div class="dialogFormBox">
			<div class="item">
				<div class="title">{{$t('common.exchange')}}</div>
				<div class="text" :class="{g_disable:isEdit}">
					<div class="select" @click="showExchange">
						<input v-model="form.exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
						<i class="iconfont el-icon-arrow-down"></i>
					</div>
				</div>
			</div>
			<!-- 添加且交易所为dex时显示选择创建或导入钱包 -->
			<div class="createOrImprot" v-if="!isEdit&&form.wallet_type==1">
				<div class="createOrImprotItem" :class="{active:selectTab==0}" @click="selectTab=0">
					<div class="tabTitle">{{$t('account.needNewWallet')}}</div>
					<div class="walletSelectBox">
						<div class="walletSelectLabel">
							<div class="recommend">{{$t('common.recommend')}}</div>
							<img v-if="selectTab==0" src="@/assets/images/createWalletActive.png" alt="">
							<img v-else src="@/assets/images/createWallet.png" alt="">
							<div>{{$t('account.createWallet')}}</div>
						</div>
						<div class="walletSelectTip">
							{{$t('account.createWalletTip')}}
						</div>
					</div>
				</div>
				<div class="createOrImprotItem" :class="{active:selectTab==1}" @click="selectTab=1">
					<div class="tabTitle">{{$t('account.hasWallet')}}</div>
					<div class="walletSelectBox">
						<div class="walletSelectLabel">
							<img v-if="selectTab==1" src="@/assets/images/importWalletActive.png" alt="">
							<img v-else src="@/assets/images/importWallet.png" alt="">
							<div>{{$t('account.importWallet')}}</div>
						</div>
						<div class="walletSelectTip">
							{{$t('account.importWalletTip1')}}
							<span>{{$t('account.importWalletTip2')}}</span>
						</div>
					</div>
				</div>
			</div>
			<!-- 非（添加dex且创建钱包）时显示数据 -->
			<template v-if="!(!isEdit&&form.wallet_type==1&&selectTab==0)">
				<div class="item">
					<div class="title" v-if="form.wallet_type==1||form.wallet_type==2">{{$t('account.walletAddress')}}</div>
					<div class="title" v-else>API Key</div>
					<div class="text">
						<input v-model="form.key" type="text" :placeholder="$t('common.input')" class="inp" />
					</div>
				</div>
				
				<div class="item">
					<div class="title">{{isT2==1? $t('account.'+label2):label2}}</div>
					<div class="text">
						<input v-model="form.secret" type="password" :placeholder="$t('common.input')" class="inp" />
					</div>
				</div>
				<!-- 附加项 -->
				<div class="item" v-if="showLabel3==1">
					<div class="title">{{isT3==1? $t('account.'+label3):label3}}</div>
					<div class="text">
						<input v-model="form.account_id" :placeholder="$t('common.input')" class="inp" />
					</div>
				</div>
			</template>
			<!-- 备注 -->
			<div class="item">
				<div class="title">{{$t('account.accountName')}}</div>
				<div class="text">
					<input v-model="form.name" type="text" :placeholder="$t('common.optional')" class="inp" />
				</div>
			</div>
			<!-- 邮箱验证码 -->
			<div class="item" v-if="isEdit||(!isEdit&&form.wallet_type==1&&selectTab==0)">
				<div class="title">{{$t('common.email')}}</div>
				<div class="text g_disable_text">{{email}}</div>
			</div>
			<div class="item" v-if="isEdit||(!isEdit&&form.wallet_type==1&&selectTab==0)">
				<div class="title">{{$t('common.verification')}}</div>
				<div class="text">
					<input
						v-model="form.sms_code"
						:placeholder="$t('common.inputVerification')"
						class="inp codeInp"
					/>
					<div v-if="codeSending" class="g_btn btn disabled">{{$t('common.countDown')}}{{ timeCount }}s</div>
					<div v-else class="g_btn btn" @click="handleSendCode">
						<template v-if="firstSend">{{$t('common.sendVerification')}}</template>
						<template v-else>{{$t('common.resend')}}</template>
					</div>
				</div>
			</div>
			<div class="tips">
				<template v-if="!(!isEdit&&form.wallet_type==1&&selectTab==0)">
					<p v-if="form.wallet_type==0||!popWeb">
						{{$t('account.whiteList')}}：<br/>
						&lt;<span class="red">{{ipWhitelist}}</span>&gt;
					</p>
					<p v-else-if="form.wallet_type==1">
						<span class="red">{{$t('account.reminder')}}</span>
						{{$t('account.dexReminder')}}
					</p>
					<p>{{$t('account.tip',{key:form.wallet_type==1||form.wallet_type==2?$t('account.walletAddress'):'API Key',secret:isT2==1? $t('account.'+label2):label2})}}</p>
				</template>

				<p>
					{{$t('account.tip2_1')}}{{$t('account.tip2_2')}}
					<!-- <span class="link" @click="jumpTg">{{$t('account.tip2_2')}}</span> -->
				</p>
			</div>
			<div class="g_dialogBtns">
				<div class="g_btn btn" @click="closePop">{{$t('common.back')}}</div>
				<div class="g_btn btn submit" @click="confirmCreate" v-if="!(!isEdit&&form.wallet_type==1&&selectTab==0)">{{$t('common.save')}}</div>
				<div class="g_btn btn submit" @click="confirmCreate" v-else>{{$t('account.confirmCreate')}}</div>
			</div>
		</div>
	</el-dialog>
	<!-- 创建主钱包成功弹窗 -->
	<el-dialog :title="$t('account.create')" :visible.sync="isTipPop" :show-close="false" :close-on-click-modal="false">
		<div class="dialogTipBox">
			<div class="sucTitle">
				<img src="@/assets/images/success2.png" alt="">
				{{$t('account.mainWalletCreateSuc')}}
			</div>
			<div class="sucSubTitle">
				{{$t('account.mainWalletCreateSub')}}
			</div>
			<div class="item">
				<div class="title">{{$t('account.walletAddress')}}</div>
				<div class="text">{{sucAddress}}</div>
			</div>
			<div class="item">
				<div class="title">{{$t('account.privateKey')}}({{$t('common.securityCode')}})</div>
				<div class="text">
					{{sucPrehalfSecret}}
					<div class="g_btn btn copyBtn" @click="handleCopyCode(sucPrehalfSecret)">{{$t('common.copy')}}</div>
				</div>
			</div>
			<div class="item">
				<div class="title">{{$t('account.accountName')}}</div>
				<div class="text">{{sucName}}</div>
			</div>
			<div class="remberTip">*{{$t('account.mainWalletCreateTip')}}</div>
			<p class="tip">
				<span class="red">{{$t('account.reminder')}}</span>
				{{$t('account.dexReminder')}}
			</p>

			<div class="checkTip" @click="isCheck=!isCheck" :class="{shake:showErr}" @animationend="showErr = false">
				<img v-if="!isCheck" src="@/assets/images/notCheck.png" alt="">
				<img v-else src="@/assets/images/isCheck.png" alt="">
				{{$t('account.mainWalletCreateCheckTip')}}
			</div>

			<div class="g_dialogBtns">
				<div class="g_btn btn" @click="confirmCreateBack3">{{$t('common.back')}}</div>
				<div class="g_btn btn submit" @click="confirmCreate3">{{$t('common.confirm')}}</div>
			</div>
		</div>
	</el-dialog>
	<!-- 删除账户 -->
	<el-dialog :title="$t('account.delAcc')" :visible.sync="showDel" @close="closeDelPop">
		<div class="dialogFormBox">
			<div class="item">
				<div class="title">{{$t('account.accountName')}}</div>
				<div class="text">
					<input v-model="form.name" type="text" readonly :placeholder="$t('common.optional')" class="inp" />
				</div>
			</div>
			<div class="item">
				<div class="title">{{$t('common.exchange')}}</div>
				<div class="text">
					<div class="select">
						<input v-model="form.exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
						<i class="iconfont el-icon-arrow-down"></i>
					</div>
				</div>
			</div>
			<div class="item">
				<div class="title">{{$t('common.email')}}</div>
				<div class="text g_disable_text">{{email}}</div>
			</div>
			<div class="item">
				<div class="title">{{$t('common.verification')}}</div>
				<div class="text">
					<input
						v-model="form.sms_code"
						:placeholder="$t('common.inputVerification')"
						class="inp codeInp"
					/>
					<div v-if="codeSending" class="g_btn btn disabled">{{$t('common.countDown')}}{{ timeCount }}s</div>
					<div v-else class="g_btn btn" @click="handleSendCode">
						<template v-if="firstSend">{{$t('common.sendVerification')}}</template>
						<template v-else>{{$t('common.resend')}}</template>
					</div>
				</div>
			</div>
			<div class="g_dialogBtns">
				<div class="g_btn btn" @click="closeDelPop">{{$t('common.back')}}</div>
				<div class="g_btn btn submit" @click="handleDelAcc">{{$t('common.del')}}</div>
			</div>
		</div>
	</el-dialog>
	<!-- 交易所选择 -->
	<exchange-dialog
		:show.sync="showExchangeDialog"
		:web="popWeb"
		@close="closeExchange"
		@select="handleSelect"
	/>
	<!-- 绑定邮箱 -->
	<emailBind ref="emailBind"></emailBind>
</div>
</template>

<style scoped="scoped" lang="stylus">
	@import 'index.styl';
</style>

<script>
	var timer;

	import Clipboard from 'clipboard';
	import { Loading } from 'element-ui';
	import ExchangeDialog from '@/components/exchange.vue';
	import emailBind from '@/components/emailBind.vue';
    import { getTradingPairInfo,setTradingPairInfo } from '@/utils/methApi/tradingPairDefault'
	// api
	import {
		getAccountList,
		setAccountItem,
		delAccountItem,
		createDexWalletsMain
	} from '@/api/account' 
    import { sendEmail } from '@/api/user'
	import {getService} from '@/api/contact'
	// methApi
	import {accountFormat2,accountFormat3} from "@/utils/methApi/accountFormat.js";

	import { mapGetters } from 'vuex'
	import bus from "../bus.js";
	export default ({
		name: 'account',
		components: {
			ExchangeDialog,
			emailBind,
		},
		data() {
			return {
				loading:null,

				isPop: false,
				isEdit: false,
				showDel:false,
				emptyImg: require('@/assets/images/norecord.png'),
				emptyImg_phone: require('@/assets/images/norecord_phone.png'),
				// 选择创建或导入钱包 0-创建 1-导入
				selectTab: 0,
				activeItem: null,

 				// 创建主钱包成功弹窗
				isTipPop: false,
				sucAddress: '',
				sucPrehalfSecret: '',
				sucName: '',
				isCheck: false,
				showErr: false,
				
				// 交易所
				showExchangeDialog: false,
				popWeb: null,
				
				accountList: [],
				form: {},
				// label类型判定
				isT2: 0,
				label2: "Api Secret",
				isT3: 0,
				label3: "Passphrase",
				showLabel3: 0,
				
				// 邮箱验证
				codeSending: false,
				timeCount: 60,
				firstSend: true,
			}
		},
		computed: {
			...mapGetters(['account','lang','isMobile','email','ipWhitelist']),
		},
		created(){
			// 如果是刚注册进入系统，则打开添加弹窗
			// if(this.$route.params.isRegister==1){
			// 	this.handleCreate()
			// }
			// 获取账号列表
			this.getAccountList()
			// 移动端右上角按钮
			bus.$on('add',this.handleCreate)
		},
		methods: {
			accountFormat2(web, web_type){
				return accountFormat2(web, web_type);
			},
			accountFormat3(web, web_type){
				return accountFormat3(web, web_type);
			},
			// label类型判定
			labelTypeData(web, web_type) {
				var account2 = accountFormat2(web, web_type);
				this.isT2 = account2.isT2;
				this.label2 = account2.label2;
				var account3 = accountFormat3(web, web_type);
				this.isT3 = account3.isT3;
				this.label3 = account3.label3;
				this.showLabel3 = account3.showLabel3;
			},
			// 交易钱包管理
			jumpSubManage(item){
				// console.log(item)
				this.$router.push({
					path:"/subManage",
					query:{
						web:item.web,
						exchange:item.web_name,
						// yenUnit1:this.yenUnit1,
						// yenUnit2:this.yenUnit2,
					}
				})
			},
			closePop() {
				this.isPop = false;
				
				this.$nextTick(()=>{
					// 初始化定时器
					clearInterval(timer);
					this.firstSend = true
					this.codeSending = false;
					this.timeCount = 60;

					// this.form = {};
					this.activeItem = null;
					this.popWeb=null;
				})
			},
			// 打开新增弹窗
			handleCreate() {
				if(!this.email){
					// 打开绑定邮箱弹窗
					this.$refs.emailBind.open()
					return
				}
				this.isPop = true;
				this.isEdit = false;
				
				this.isT2= 0;
				this.label2= "Api Secret";
				this.isT3= 0;
				this.label3= "Passphrase";
				this.showLabel3= 0;
				this.form = {};
			},
			// 新增/编辑
			confirmCreate() {
				//  添加dex且选择创建钱包时 走创建主钱包流程
				if(!this.isEdit&&this.form.wallet_type==1&&this.selectTab==0){
					// 创建主钱包
					this.confirmCreate2()
				}
				// 绑定账号流程
				else if(!this.form.web){
					this.$message({
						message: this.$t('tip.selectExchange'),
						center: true,
						type: 'error',
						offset: 100,
						customClass: 'shotMsg',
					});
				}else if(!this.form.key){
					var name;
					if(this.form.wallet_type==1||this.form.wallet_type==2){
						name=this.$t('account.walletAddress')
					}else{
						name='API Key'
					}
					this.$message({
						message: this.$t('tip.pleaseEnter',{name})+'！',
						center: true,
						type: 'error',
						offset: 100,
						customClass: 'shotMsg',
					});
				}else if(!this.form.secret){
					var name;
					if(this.isT2 == 0){
						name = this.label2
					}else{
						name = this.$t('account.'+this.label2)
					}
					this.$message({
						message: this.$t('tip.pleaseEnter',{name})+'！',
						center: true,
						type: 'error',
						offset: 100,
						customClass: 'shotMsg',
					});
				}else if(this.form.key == this.form.secret){
					var name1,name2;
					if(this.form.wallet_type==1||this.form.wallet_type==2){
						name1=this.$t('account.walletAddress')
					}else{
						name1='API Key'
					}
					if(this.isT2 == 0){
						name2 = this.label2
					}else{
						name2 = this.$t('account.'+this.label2)
					}
					this.$message({
						message: this.$t('account.keySecretSame',{name1,name2})+'！',
						center: true,
						type: 'error',
						offset: 100,
						customClass: 'shotMsg',
					});
				}else if(!this.form.sms_code&&this.isEdit){
					this.$message({
						message: this.$t('tip.codeEmpty')+'！',
						type: 'error',
						center: true,
						offset: 100,
						customClass: 'shotMsg',
					});
				}
				// 添加操作、是已绑定的交易所
				else if(!this.isEdit&&!!this.accountList&&this.accountList.some(item => item.web === this.form.web)){
					this.$message({
						message: this.$t('account.isCreateAddTip'),
						type: 'error',
						center: true,
						offset: 100,
						customClass: 'shotMsg',
					});
				}else{
					// 附加项
					if(this.showLabel3 == 1 && !this.form.account_id){
						var name
						if(this.isT3 == 0){
							name = this.label3
						}else{
							name = this.$t('account.' + this.label3)
						}
						this.$message({
							message: this.$t('tip.pleaseEnter',{name})+'！',
							center: true,
							type: 'error',
							offset: 100,
							customClass: 'shotMsg',
						});
					}else{
						this.loading = Loading.service({
							text: 'Loading',
							spinner: 'el-icon-loading',
							background: 'rgba(0, 0, 0, 0.7)',
							customClass: 'apploading',
						});
						// 提交表单信息--关闭弹框--获取新列表
						setAccountItem({
							id:this.isEdit?this.accountList[this.activeItem].id:0,
							name:this.form.name,
							web:this.form.web,
							key:this.form.key,
							secret:this.form.secret,
							exchange_account_id:this.form.account_id||0,
							account:this.account,
							email:this.email,
							sms_code:this.form.sms_code,
						}).then(res=>{
							if(res==1){
								// this.getAccountList();
								// 打开盘口选择弹窗
								localStorage.setItem('oldWebId', getTradingPairInfo('web_id'))
								localStorage.setItem('hasBind',1)
								localStorage.setItem('hasEnter',0)
								// 创建或编辑都修改本地存储的交易所
								setTradingPairInfo({
									exchange:this.form.exchange,
									web:this.form.web,
									web_id:this.form.web_id,
									web_type:this.form.wallet_type,
									yenUnit1: getTradingPairInfo('yenUnit1'),
									yenUnit2: getTradingPairInfo('yenUnit2'),
								})
												
								if(!this.isEdit){
									this.$message({
										message: this.$t('tip.addSuc'),
										center: true,
										type: 'success',
										offset: 100,
										customClass: 'shotMsg',
									});
									// 如果是dex进入钱包管理，否则进入手动操盘
									if(this.form.wallet_type==1||this.form.wallet_type==2){
										this.$router.push({
											path:"/subManage",
											query:{
												web:this.form.web,
												exchange:this.form.exchange,
											}
										})
									}else{
										this.$router.push({path:"/stocks"})
									}
								}else{
									this.$message({
										message: this.$t('tip.editSuc'),
										center: true,
										type: 'success',
										offset: 100,
										customClass: 'shotMsg',
									});
									this.$router.push({path:"/stocks"})
								}
								this.isPop = false;
							}else{
								if(!this.isEdit){
									this.$message({
										message: this.$t('tip.addErr'),
										center: true,
										type: 'error',
										offset: 100,
										customClass: 'shotMsg',
									});
								}else{
									this.$message({
										message: this.$t('tip.editErr'),
										center: true,
										type: 'error',
										offset: 100,
										customClass: 'shotMsg',
									});
								}
							}
							this.loading.close()
						}).catch(err=>{
							this.loading.close()
						})
					}
				}
			},
			// 创建主钱包
			confirmCreate2(){
				this.loading = Loading.service({
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)',
					customClass: 'apploading',
				});
				createDexWalletsMain({
					web:this.form.web,
					account:this.account,
					sms_code:this.form.sms_code,
					name:this.form.name,
				}).then(res=>{
					localStorage.setItem('oldWebId', getTradingPairInfo('web_id'))
					localStorage.setItem('hasBind',1)
					localStorage.setItem('hasEnter',0)
					// 创建或编辑都修改本地存储的交易所
					setTradingPairInfo({
						exchange:this.form.exchange,
						web:this.form.web,
						web_id:this.form.web_id,
						web_type:this.form.wallet_type,
						yenUnit1: getTradingPairInfo('yenUnit1'),
						yenUnit2: getTradingPairInfo('yenUnit2'),
					})

					// console.log(res)
					this.sucAddress = res.address
					this.sucPrehalfSecret = res.prehalf_secret
					this.sucName = this.form.name

					this.isPop = false;
					this.isTipPop = true;

					// 更新数据
					this.getAccountList();

					this.loading.close()
				}).catch(err=>{
					this.loading.close()
				})
			},
			// 创建主钱包成功后续操作
			confirmCreate3(){
				if(this.isCheck){
					// 如果是dex进入钱包管理，否则进入手动操盘
					// if(this.form.wallet_type==1||this.form.wallet_type==2){
					this.$router.push({
						path:"/subManage",
						query:{
							web:this.form.web,
							exchange:this.form.exchange,
						}
					})
					// }else{
					// 	this.$router.push({path:"/stocks"})
					// }
					this.isTipPop = false;
				}else{
					this.showErr = true
					// 请妥善保存您的私钥部分！如果遗失，资金将无法找回，且永远丢失！
					this.$message({
						message: this.$t('account.mainWalletCreateTip'),
						center: true,
						type: 'error',
						offset: 100,
						customClass: 'shotMsg',
					});
				}
			},
			// 创建主钱包成功后续操作-返回
			confirmCreateBack3(){
				if(this.isCheck){
					this.isTipPop = false;
				}else{
					this.showErr = true
					// 请妥善保存您的私钥部分！如果遗失，资金将无法找回，且永远丢失！
					this.$message({
						message: this.$t('account.mainWalletCreateTip'),
						center: true,
						type: 'error',
						offset: 100,
						customClass: 'shotMsg',
					});
				}
			},

			// 交易所
			showExchange() {
				if(this.isEdit)return
				this.showExchangeDialog = true;
			},
			closeExchange() {
				this.showExchangeDialog = false;
			},
			handleSelect(data) {
				this.labelTypeData(data.web,data.web_type)
				this.form.exchange = data.text;
				this.form.web = data.web;
				this.form.wallet_type = data.web_type;
				this.form.web_id = data.web_id;
				this.popWeb=data.web;
				this.showExchangeDialog = false;
			},

			// 打开编辑弹窗
			handleChange(index) {
				if(!this.email){
					// 打开绑定邮箱弹窗
					this.$refs.emailBind.open()
				}else{
					this.activeItem = index;
					this.form = Object.assign({},this.accountList[index])

					this.form.exchange = this.accountList[index].web_name
					this.popWeb=this.accountList[index].web

					this.labelTypeData(this.form.web,this.form.wallet_type)
					// 获取账号信息--显示弹窗
					this.isPop = true;
					this.isEdit = true;
				}
			},
			// 打开删除弹窗
			handleDelete(index) {
				if(!this.email){
					// 打开绑定邮箱弹窗
					this.$refs.emailBind.open()
					return
				}

				this.showDel=true;
				this.activeItem = index;
				this.form =Object.assign({},this.accountList[index])
				this.form.exchange=this.accountList[index].web_name
				this.popWeb=this.accountList[index].web
			},
			closeDelPop(){
				this.showDel=false;
				this.activeItem = null;
			},
			// 删除账号操作
			handleDelAcc(){
				if(!this.form.sms_code){
					this.$message({
						message: this.$t('tip.codeEmpty')+'！',
						type: 'error',
						center: true,
						offset: 100,
						customClass: 'shotMsg',
					});
				}else{
					this.loading = Loading.service({
						text: 'Loading',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)',
						customClass: 'apploading',
					});
					delAccountItem({
						web:this.accountList[this.activeItem].web,
						account:this.account,
						access_id:this.accountList[this.activeItem].id,
						email:this.email,
						sms_code:this.form.sms_code,
					}).then(res=>{
						// console.log(res)
						if(res==1){
							this.loading.close()

							this.getAccountList();
							this.$message({
								message: this.$t('tip.delSuc'),
								type: 'success',
								center: true,
								offset: 100,
								customClass: 'shotMsg',
							});
						}else{
							// 删除失败
							this.$message({
								message: this.$t('tip.delErr'),
								type: 'error',
								center: true,
								offset: 100,
								customClass: 'shotMsg',
							});
						}
						
						this.showDel=false;
						this.activeItem = null;
					}).catch(err=>{
						this.loading.close()
					})
				}
			},
			// 获取账号列表
			getAccountList(){
				this.loading = Loading.service({
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)',
					customClass: 'apploading',
				});
			
				getAccountList({account:this.account}).then(res => {
					// console.log(res)
					this.accountList=res
					this.loading.close();
				}).catch(err=>{
					this.loading.close();
				});
			},

			// 复制
			handleCopyCode(code) {
				let clipboard = new Clipboard('.copyBtn', {
					text: () => {
						return code
					}
				})
				clipboard.on('success', () => {
					this.$message({
						message: this.$t('tip.copysuccess'),
						type: 'success',
						center: true,
						customClass: 'shotMsg',
					});
					clipboard.destroy();
				})
				clipboard.on('error', () => {
					this.$message({
						message: this.$t('tip.copyerr'),
						type: 'error',
						center: true,
						customClass: 'shotMsg',
					});
					clipboard.destroy();
				})
			},
			
			// 发送验证码
			handleSendCode(type) {
				if(!this.codeSending){
					// 发送验证码
					this.timeCount = 60;
					this.codeSending = true;
					let that = this;
					this.firstSend = false;
					clearInterval(timer);
					timer = setInterval(() => {
						if(this.timeCount >= 1) {
							that.timeCount -= 1;
						} else {
							clearInterval(timer);
							this.codeSending = false;
						}
					}, 1000);
				
					sendEmail({
						account:this.account,
						email:this.email,
						type:1,
					})
				}
			},
			// 联系客服
			jumpTg(){
				this.loading = Loading.service({
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)',
					customClass: 'apploading',
				});
				getService({
					account:this.account,
					source:1,//0-微信，1-telegram，2-Facebook，3-discord
				}).then(res=>{
					if(!!res){
						if(res.length==1){
							// 如果只有一个联系方式 直接打开
							window.location.href=res[0].wechat
						}else if(res.length>1){
							// 如果有多个联系方式
							try {
								res.forEach(item => {
									if(item.nickname=='系统客服'){
										window.location.href=item.wechat
										throw Error()
									}
								});
							} catch (error) {
								
							}
						}else{
							// 没有联系方式
						}
					}
					this.loading.close()
				}).catch(err=>{
					this.loading.close()
				})
			},
		},
		beforeDestroy() {
			if(timer){
				clearInterval(timer)
				timer=''
			}
			// 在页面销毁前取消事件监听
  			bus.$off('add',this.handleCreate);
			// 子组件销毁
			if(this.$refs.emailBind){
				this.$refs.emailBind.beforeDestroy()
			}
		}
	})
</script>
